<template>
    <AppPanel subtitle="Listagem de funcionarios">
        <template #content>
            <AppPaginatedGrid ref="grid" :service="service" newPath="/questionario-telemedicina/new"
                editPath="/questionario-telemedicina/" :expander="false" subtitle="Questionário Telemedicina"
                tooltip="Cadastro Questionário Telemedicina" nomeTelaDoManual="questionario-telemedicina-list"
                origemHistoricoAcao="/questionario-telemedicina">
                <template #columns>
                    <Column field="pergunta" header="Pergunta" :sortable="true" sortField="pergunta"></Column>
                    <Column field="tipo" header="Tipo" :sortable="true" sortField="tipo">
                        <template #body="slotProps">
                            {{
                slotProps.data.tipo === 'CB'
                    ? 'Combo'
                    : slotProps.data.tipo === 'ME'
                        ? 'Múltipla Escolha'
                        : slotProps.data.tipo === 'DI'
                            ? 'Dissertativa'
                            : slotProps.data.tipo === 'IN'
                                ? 'Inteiro'
                                : ''
            }}
                        </template>
                    </Column>
                    <Column field="mostrarPaciente" header="Mostra paciente" :sortable="true"
                        sortField="mostrarPaciente">
                        <template #body="slotProps">
                            {{ slotProps.data.mostrarPaciente ? 'Sim' : 'Não' }}
                        </template>
                    </Column>
                    <Column field="opcoes" header="Opções" :sortable="true" sortField="opcoes"></Column>
                    <Column field="ordem" header="Ordem" :sortable="true" sortField="ordem"></Column>
                </template>
            </AppPaginatedGrid>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';

export default {
    data() {
        return {
            service: null
        };
    },
    created() {
        this.service = new BaseService('/questionario-telemedicina');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
